body {
  font-size: 12px;
  line-height: 1.5;
}
p {
  font-size: 1em;
  line-height: 1.5em;
}

/*h1 {*/
/*  font-size: 2.5em; !* = 40px/16px *!*/
/*  line-height: 1.1em; !* = 44px/40px *!*/
/*  margin-bottom: 22px;*/
/*}*/
/*p {*/
/*  font-size: 0.875em; !* 16px is the default em size *!*/
/*  line-height: 1.5714285714285714em; !* = 22px/14px *!*/
/*  margin-bottom: 22px;*/
/*}*/

/* Quick Styles AP*/

.video-container {
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;
  margin-bottom: 2em;
}

.video-container iframe, .video-container object, .video-container embed {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

/* SVG scaling */
.scaling-svg-container {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
  /* override this inline for aspect ratio other than square */
}
.scaling-svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
/* end SVG scaling */
.hidden {
  display: none;
}
.aria-hidden {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

.pointer {
  cursor: pointer;
}

.animate-opacity {
  animation:opac 0.8s}@keyframes opac{from{opacity:0} to{opacity:1}
}
/* Settings */
.settings-padding-bottom {
  padding-bottom: 16px;
}
/* End Settings*/

.seqBox {
  display: inline-block;
  margin: .5em;
  border-radius: .5em;
  width: 2em;
  height: 2em;
  background-color: grey;
  cursor: pointer;
}
.seqBox-on {
  background-color: #cddc39;
}
.seqBox-off {
  background-color: #9e9e9e;
}
.stringSVG, .stringSVGClickTarget {
  cursor: pointer;
}
.noteText {
  font-size: 60px;
  fill: #F79421;
  dominant-baseline: central;
  cursor: pointer;
}
.vibrate {
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}